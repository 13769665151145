import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { doc } from "../assets";

const Document = () => {
  const { id } = useParams();
  const [skeleton, setSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingToCreate, setLoadingToCreate] = useState(false);
  const [loadingToUpdate, setLoadingToUpdate] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedFile, setEditedFile] = useState(null);

  const authToken = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : null;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_STORAGE_URL = process.env.REACT_APP_API_MEDIA_URL;

  const documents = [
    { id: "1", title: "Lexical Lists & Dictionaries", endpointPath: "/lists" },
    { id: "2", title: "Grammars", endpointPath: "/grammas" },
    { id: "3", title: "LOT Project Occasional Papers", endpointPath: "/projects" },
    { id: "4", title: "Theses & Dissertations", endpointPath: "/theses" },
    { id: "5", title: "Maps, Facts & Figures", endpointPath: "/facts-figure" },
    { id: "6", title: "Socio-cultural Issues", endpointPath: "/social" },
    { id: "7", title: "Language in Education", endpointPath: "/language-education" },
    { id: "8", title: "Language and Technology", endpointPath: "/language-technology" },
    { id: "9", title: "Indigenous Knowledge", endpointPath: "/indigenous-knowledge" },
    { id: "10", title: "Language Description", endpointPath: "/language-description" },
  ];

  const document = documents.find((doc) => doc.id === id);

  const openViewDialog = (doc) => {
    setSelectedDocument(doc);
    setIsViewDialogOpen(true);
  };

  const closeViewDialog = () => {
    setSelectedDocument(null);
    setIsViewDialogOpen(false);
  };

  const [allDocuments, setAllDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newDocName, setNewDocName] = useState("");
  const [newDocFile, setNewDocFile] = useState(null);
  const [newDocUrl, setNewDocUrl] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
  });

  useEffect(() => {
    // Fetch documents once when the page loads
    fetchDocuments();
  }, []); // Only runs once when the component mounts

  useEffect(() => {
    // Filter documents based on the search query
    const filtered = allDocuments.filter((doc) =>
      doc.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDocuments(filtered);
  }, [searchQuery, allDocuments]); // Runs only when searchQuery or allDocuments change

  const fetchDocuments = async (url = `${API_BASE_URL}${document.endpointPath}`) => {
    setSkeleton(true);
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setAllDocuments(response.data.data || []);
      setPagination({
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        next_page_url: response.data.next_page_url,
        prev_page_url: response.data.prev_page_url,
      });
    } catch (error) {
      toast.error("Failed to fetch documents.");
      setSkeleton(false);
      setAllDocuments([]);
    } finally {
      setSkeleton(false);
    }
  };

  const createDocument = async () => {
    if (!newDocName || !newDocFile) {
      toast.error("Name and file are required.");
      return;
    }

    const formData = new FormData();
    formData.append("name", newDocName);
    formData.append("file", newDocFile);
    if (newDocUrl) formData.append("url", newDocUrl);

    setLoadingToCreate(true);

    try {
      await axios.post(
        `${API_BASE_URL}${document.endpointPath}`,
        formData,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.success("Document created successfully.");
      setIsCreateDialogOpen(false);
      setNewDocName("");
      setNewDocFile(null);
      setNewDocUrl("");
      fetchDocuments();
    } catch (error) {
      setLoadingToCreate(false);
      toast.error("Failed to create document.");
    } finally {
      setLoadingToCreate(false);
    }
  };

  const handleFileChange = (e) => {
    setNewDocFile(e.target.files[0]);
  };

  const EditDocument = () => {
    setIsEditMode(true);
    setEditedName(selectedDocument.name); // Pre-fill with the current document name
    setEditedFile(null); // Keep file null initially
  };

  const updateDocument = async () => {
    setLoadingToUpdate(true);
    const formData = new FormData();
    formData.append("name", editedName);
    if (editedFile) {
      formData.append("file", editedFile);
    }

    try {
      await axios.post(
        `${API_BASE_URL}${document.endpointPath}/${selectedDocument.id}`,
        formData,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.success("Document updated successfully.");
      setIsEditMode(false); // Exit edit mode
      fetchDocuments(); // Refresh the document list
      closeViewDialog(); // Close the dialog
    } catch (error) {
      toast.error("Failed to update document.");
      setLoadingToUpdate(false);
    } finally {
      setLoadingToUpdate(false);
      setIsEditMode(false);
    }
  };

  const deleteDocument = async (docId) => {
    setLoadingToDelete(true);
    try {
      await axios.delete(
        `${API_BASE_URL}${document.endpointPath}/${docId}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.success("Document deleted successfully.");
      fetchDocuments();  // To refresh the document list
    } catch (error) {
      toast.error("Failed to delete document.");
      setLoadingToDelete(false);
    } finally {
      setLoadingToDelete(false);
      setIsEditMode(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex w-full justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{document?.title || "Document Management"}</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Create Document
        </button>
      </div>

      {/* Search */}
      {allDocuments.length > 0 && (
        <div className="mb-6">
          <input
            type="text"
            className="border rounded-lg p-2 w-full"
            placeholder="Search document"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      )}

      {/* List Documents */}
      <div className="bg-white shadow rounded-lg">
        {filteredDocuments.length === 0 ? (
          <div className="p-4 text-gray-500 text-center">No documents available.</div>
        ) : (
          <ul>
            {filteredDocuments.map((doc) => (
              <li key={doc.id} className="border-b last:border-b-0 p-4 flex justify-between">
                <span>{doc.name}</span>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openViewDialog(doc);
                  }}
                  className="text-white bg-blue-500 p-1 w-12 text-center rounded-md hover:font-bold"
                >
                  View
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50"
          onClick={() => fetchDocuments(pagination.prev_page_url)}
          disabled={!pagination.prev_page_url}
        >
          Previous
        </button>
        <span>
          Page {pagination.current_page} of {pagination.last_page}
        </span>
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50"
          onClick={() => fetchDocuments(pagination.next_page_url)}
          disabled={!pagination.next_page_url}
        >
          Next
        </button>
      </div>

      {/* Create Document Dialog */}
      {isCreateDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow w-96">
            <h2 className="text-lg font-bold mb-4">Create Document</h2>
            <div className="mb-4">
              <label className="block mb-2 font-medium">Name</label>
              <input
                type="text"
                className="border p-2 w-full rounded"
                value={newDocName}
                onChange={(e) => setNewDocName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-medium">File</label>
              <input
                type="file"
                className="border p-2 w-full rounded"
                onChange={handleFileChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-medium">URL (Optional)</label>
              <input
                type="text"
                className="border p-2 w-full rounded"
                value={newDocUrl}
                onChange={(e) => setNewDocUrl(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              {loadingToCreate ? (
                <div className="bg-zinc-500 text-white px-4 py-2 rounded mr-2">Creating...</div>
              ) : (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                  onClick={createDocument}
                >
                  Create
                </button>
              )}
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setIsCreateDialogOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isViewDialogOpen && selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow w-96">
            <h2 className="text-lg font-bold mb-4">{selectedDocument.name}</h2>

            {/* Edit or View Content */}
            {!isEditMode ? (
              <div className="mb-4 flex flex-col items-center hover:bg-indigo-100">
                <a
                  href={`${API_STORAGE_URL}/${selectedDocument.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="h-[250px] w-[250px] object-center" src={doc} alt="Document" />
                  <div className="text-blue-500 hover:underline text-center">Open File</div>
                </a>
              </div>
            ) : null}

            {/* Edit Mode */}
            {isEditMode && (
              <div className="mt-4">
                <div className="mb-4">
                  <label className="block mb-2 font-medium">Name</label>
                  <input
                    type="text"
                    className="border p-2 w-full rounded"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2 font-medium">File (Optional)</label>
                  <input
                    type="file"
                    className="border p-2 w-full rounded"
                    onChange={(e) => setEditedFile(e.target.files[0])}
                  />
                </div>
                <div className="flex justify-center">
                  {loadingToUpdate ? (
                    <div className="bg-zinc-500 text-white px-4 py-2 rounded mr-2">Updating...</div>
                  ) : (
                    <button
                      className="bg-yellow-500 hover:bg-blue-500 text-white px-4 py-2 rounded mr-2"
                      onClick={updateDocument}
                    >
                      Update
                    </button>
                  )}
                  <button
                    className="bg-gray-300 px-4 py-2 rounded"
                    onClick={() => setIsEditMode(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-between mt-4">
              {!isEditMode && (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-green-400"
                  onClick={EditDocument}
                >
                  Edit
                </button>
              )}
              {loadingToDelete ? (
                <div className="text-white px-4 py-2 rounded bg-red-600">Deleting...</div>
              ) : (
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={() => {
                    deleteDocument(selectedDocument.id);
                    closeViewDialog();
                  }}
                >
                  Delete
                </button>
              )}
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={closeViewDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Document;