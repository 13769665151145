import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { toast } from 'react-toastify'; // Import toast if you want notifications

const Sidebar = () => {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Determine which user data to use
  const user = userData || currentUser?.user;

  const fetchUserData = async () => {
    if (!currentUser?.token || !currentUser?.user?.id) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${currentUser.user.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${currentUser?.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        console.error('Failed to fetch user data:', response.status);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchUserData(); // Initial fetch

  //   // Polling interval to fetch user data periodically
  //   const intervalId = setInterval(fetchUserData, 5000); // Poll every 5 seconds

  //   // Clean up polling on component unmount
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [currentUser?.token]);

  // useEffect(() => {
  //   const handleOnline = () => {
  //     setIsOnline(true);
  //     toast.success('You are back online!', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   };

  //   const handleOffline = () => {
  //     setIsOnline(false);
  //     toast.error('You are offline!', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   };

  //   window.addEventListener('online', handleOnline);
  //   window.addEventListener('offline', handleOffline);

  //   return () => {
  //     window.removeEventListener('online', handleOnline);
  //     window.removeEventListener('offline', handleOffline);
  //   };
  // }, []);

  return (
    <div className="flex h-full w-full flex-col text-white justify-between items-center">
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-col items-center justify-center">
          <Link to="/profile">
            {/* {loading ? (
              <div className="mt-5 h-[100px] w-[100px] bg-gray-500 rounded-full animate-pulse" />
            ) : ( */}
              <img
                src={`${process.env.REACT_APP_API_MEDIA_URL}/${user?.image}`}
                alt="User Profile"
                className="mt-5 hover:border-blue-500 border-2 h-[100px] w-[100px] object-cover flex-shrink-0 bg-zinc-100 rounded-full"
              />
            {/* )} */}
          </Link>
          <div className="text-xs m-[1px] mt-1 font-semibold text-slate-600">TSL Admin</div>
          <div className="mb-1">{user?.name}</div>
        </div>
        <ul>
          <li><Link to="/" className="block p-4 hover:bg-gray-700">Dashboard</Link></li>
          <li><Link to="/manage-words" className="block p-4 hover:bg-gray-700">All Words</Link></li>
          <li><Link to="/add-word" className="block p-4 hover:bg-gray-700">Add Word</Link></li>
        </ul>
      </div>
      <div className="mb-6">
        <Link
          to="/logout"
          className="text-center flex justify-center items-center h-[40px] w-[100px] bg-red-500 rounded-lg hover:bg-red-600"
        >
          Log Out
        </Link>
        <div className="text-center text-xs my-1">
          {isOnline ? (
            <span className="text-green-500">⦿ Online</span>
          ) : (
            <span className="text-red-500">⦿ Offline</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;