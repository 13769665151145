import React, { useContext } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthContext } from './context/authContext';
import Sidebar from './components/Sidebar';
import ManageWords from './pages/ManageWords';
import AddWord from './pages/AddWord';
import MobileNavigation from './components/MobileNavigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditWord from './pages/UpdateWord';
// import EditWord from './pages/EditWord';
import Logout from './Logout';
import Profile from './pages/Profile';
import Document from './pages/Document';
import LectureNotes from './components/LectureNotes';

function App() {
  const { currentUser } = useContext(AuthContext);

  const PrivateRoute = ({ element, ...props }) => {
    const { currentUser } = useContext(AuthContext);
    return currentUser ? element : <Navigate to="/login" />;
  };

  const PublicRoute = ({ element, ...props }) => {
    const { currentUser } = useContext(AuthContext);
    return currentUser ? <Navigate to="/" /> : element;
  };

  const Layout = () => {
    return (
      <div className='flex fixed flex-col w-full h-screen'>
        <div className='flex w-full md:hidden bg-[#0F002E]'>
          <Navbar />
        </div>
        <div className='flex gap-5 w-[98%] max-md:w-full h-screen'>
          <div className='flex max-md:hidden w-64 flex-col bg-[#0F002E] h-full'>
            <Sidebar />
          </div>
          <div className='flex-1 overflow-y-auto scrollbar-hide bg-soft md:py-5'>
            <Outlet />
          </div>
        </div>
        <div className='flex fixed bottom-0 w-full md:hidden bg-[#0F002E] h-[50px]'>
          <MobileNavigation />
        </div>
      </div>
    )
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <PrivateRoute element={<Dashboard />} />,
        },
        {
          path: "/profile",
          element: <PrivateRoute element={<Profile />} />,
        },
        {
          path: "/add-word",
          element: <PrivateRoute element={<AddWord />} />,
        },
        {
          path: "/manage-words",
          element: <PrivateRoute element={<ManageWords />} />,
        },
        {
          path: "/dashboard/edit-word/:id",
          element: <PrivateRoute element={<EditWord />} />,
        },
        {
          path: "/document/:id",
          element: <PrivateRoute element={<Document />} />,
        },
        {
          path: "/notices/:id",
          element: <PrivateRoute element={<Document />} />,
        },
        // {
        //   path: "/learnings",
        //   element: <PrivateRoute element={<Learning />} />,
        // },
        {
          path: "/learning/lecture-notes/:id",
          element: <PrivateRoute element={<LectureNotes />} />,
        },
      ],
    },
    // {
    //   children: [
    //     {
    //       path: "/document",
    //       element: <PrivateRoute element={<Document />} />,
    //     },
    //   ]
    // },
    {
      children: [
        {
          path: "/login",
          element: <PublicRoute element={<Login />} />,
        },
        {
          path: "/register",
          element: <PublicRoute element={<Register title="Register Profile" />} />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
      ]
    }
  ]);

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;