import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/authContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import toast if you want notifications

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Determine which user data to use
  const user = userData || currentUser?.user;

  const fetchUserData = async () => {
    if (!currentUser?.token || !currentUser?.user?.id) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/${currentUser.user.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${currentUser?.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        console.error('Failed to fetch user data:', response.status);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchUserData(); // Initial fetch

  //   // Polling interval to fetch user data periodically
  //   const intervalId = setInterval(fetchUserData, 5000); // Poll every 5 seconds

  //   // Clean up polling on component unmount
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [currentUser?.token]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success('You are back online!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.error('You are offline!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className="w-full text-white p-4 flex items-center justify-between">
      <Link to="/" className="text-xl font-bold">TSL Admin</Link>
      <div className="flex items-center">
        <div className="text-center text-xs my-1 mr-4">
          {isOnline ? (
            <span className="text-green-500">⦿ Online</span>
          ) : (
            <span className="text-red-500">⦿ Offline</span>
          )}
        </div>
        <Link to="/profile">
          {/* {loading ? (
            <div className="ml-4 h-[40px] w-[40px] bg-gray-500 rounded-full animate-pulse" />
          ) : ( */}
            <img
              src={`${process.env.REACT_APP_API_MEDIA_URL}/${user?.image}`}
              alt="User Profile"
              className="ml-4 h-[40px] w-[40px] object-cover bg-zinc-100 rounded-full"
            />
          {/* )} */}
        </Link>
      </div>
    </div>
  );
};

export default Navbar;