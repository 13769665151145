import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { FaCircle } from "react-icons/fa";
import LECOManager from '../components/LECOManager';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [activeView, setActiveView] = useState('Dashboard');
  const [duration, setDuration] = useState(28);
  const [userData, setUserData] = useState(null);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [users, setUsers] = useState({ data: [] });
  const [words, setWords] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [loadingCurrent, setLoadingCurrent] = useState(false);

  const getAuthToken = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      return user.token;
    }
    return null;
  };

  // Function to get the XSRF token from cookies
  const getXSRFToken = () => {
    return Cookies.get('XSRF-TOKEN');
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();
      const xsrfToken = getXSRFToken();
      setLoading(true);

      try {
        // Fetching users
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-XSRF-TOKEN': xsrfToken,
            },
          }
        );
        setUsers(usersResponse.data);

        // Fetching words
        const wordsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/words`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'X-XSRF-TOKEN': xsrfToken,
            },
          }
        );
        setWords(wordsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  // Function to fetch user data based on duration
  const fetchUserData = async () => {
    setLoading(true);
    try {
      const token = getAuthToken();
      const xsrfToken = getXSRFToken();

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/active-users/over-time?duration=${duration}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch current active users
  const fetchCurrentUsers = async () => {
    setLoadingCurrent(true);
    try {
      const token = getAuthToken();
      const xsrfToken = getXSRFToken();

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/active-users/current`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': xsrfToken,
          },
        }
      );

      setCurrentUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching current active users:', error);
    } finally {
      setLoadingCurrent(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchCurrentUsers();
  }, [duration]);

  const chartData = {
    labels: userData ? userData.data.map(entry => entry.date) : [],
    datasets: [
      {
        label: 'Active Users',
        data: userData ? userData.data.map(entry => entry.active_users) : [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Active Users Over Time',
      },
    },
    animation: {
      duration: 1500,
      easing: 'easeInOutQuart',
    },
  };

  return (
    <div className='flex flex-col w-full h-full px-1'>
      <div className='flex w-full justify-between items-center'>
        <div className='flex w-full h-auto justify-between mt-2'>
          <div className='text-[24px] font-bold mb-6'>
            {activeView === 'Dashboard' ? 'Dashboard' : 'LECO Manager'}
          </div>
          <div
            onClick={() => setActiveView(activeView === 'Dashboard' ? 'LECO Manager' : 'Dashboard')}
            className={`relative max-sm:mt-1 w-16 h-8 rounded-full flex items-center cursor-pointer transition-colors duration-300 pl-1 ${activeView === 'Dashboard' ? "bg-white border-black border-2" : "bg-blue-500"}`}
          >
            <div
              className={`absolute top-1/2 transform -translate-y-1/2 w-6 h-6 rounded-full flex items-center justify-center shadow-md transition-transform duration-300 ${activeView === 'Dashboard' ? 'translate-x-[calc(100%-1.5rem)] bg-black' : 'translate-x-8 bg-white'
                }`}
            >
              <FaCircle className={`${activeView === 'Dashboard' ? "text-white" : "text-blue-500"}`} />
            </div>
          </div>
        </div>
      </div>

      {/* Dashboard */}
      {activeView === 'Dashboard' && (
        <div>
          <div className='flex flex-col max-md:flex-col-reverse'>
            {/* <div className='flex max-md:flex-col gap-4'>
          <div className=''>
            <div className='text-blue-500 cursor-pointer'>Activities</div>
            <div className='max-md:w-full h-[200px] w-[400px] bg-zinc-100 animate-pulse rounded-[8px] p-2'></div>
          </div>
          <div className=''>
            <div className='text-blue-500 cursor-pointer'>Notifications</div>
            <div className='max-md:w-full h-[200px] w-[400px] bg-zinc-100 animate-pulse rounded-[8px] p-2'></div>
          </div>
        </div> */}
            <div className="flex gap-4 mt-5 max-md:mt-0">
              <div className="w-full">
                <div className="text-blue-500 cursor-pointer">Users</div>
                <div
                  className={`max-md:w-[90%] h-[100px] w-[300px] text-[#FF511B] ${loading ? 'animate-pulse bg-zinc-200' : 'text-5xl'} font-medium rounded-[8px]`}
                >
                  {!loading && users.total}
                </div>
              </div>
              <div className="w-full">
                <div className="text-blue-500 cursor-pointer">Words</div>
                <div
                  className={`max-md:w-[90%] h-[100px] w-[300px] text-[#12E400] ${loading ? 'animate-pulse bg-zinc-200' : 'text-5xl'} font-medium rounded-[8px]`}
                >
                  {!loading && words.total}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full gap-4 h-fit pb-[100px]'>
            <div className='text-blue-500 cursor-pointer'>Statists</div>
            <div className='w-full p-6 bg-zinc-100 h-fit mb-10'>
              <h2 className="text-2xl font-bold mb-4">Active Users Dashboard</h2>

              {/* Duration Selector */}
              <div className="mb-6">
                <label htmlFor="duration" className="mr-2">Select Duration:</label>
                <select
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="p-2 border rounded-md"
                >
                  <option value="1">1 Day</option>
                  <option value="7">7 Days</option>
                  <option value="14">14 Days</option>
                  <option value="28">28 Days</option>
                </select>
              </div>

              {/* Display loading message */}
              {loading && <p>Loading data...</p>}

              {/* Display animated chart */}
              {!loading && userData && (
                <div className="w-full max-w-2xl mx-auto mb-8">
                  <Bar data={chartData} options={options} />
                </div>
              )}

              {/* Display message if no data */}
              {!loading && !userData && <p>No data available for the selected duration.</p>}

              {/* Display Current Active Users */}
              <h3 className="text-xl font-semibold mb-2">Current Active Users</h3>
              {loadingCurrent ? (
                <p>Loading current active users...</p>
              ) : currentUsers.length > 0 ? (
                <ul className="mb-6">
                  {currentUsers.map((user, index) => (
                    <li key={index} className="p-2 border-b border-gray-200">
                      <p><strong>IP Address:</strong> {user.ip_address}</p>
                      <p><strong>Location:</strong> {user.location.city}, {user.location.region}, {user.location.country}</p>
                      <p><strong>Username:</strong> {user.username}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No current active users found.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* LECO Manager */}
      {activeView === 'LECO Manager' && (
        <LECOManager />
      )}
    </div>
  );
};

export default Dashboard;