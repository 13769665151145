import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const EditWord = ({ id }) => {
  const navigate = useNavigate();

  // State to hold fetched word details
  const [word, setWord] = useState({
    english: '',
    swahili: '',
    englishDescription: '',
    swahiliDescription: '',
    video: '',
    image: '',
    images: [],
  });

  // State for preview and file inputs
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [changes, setChanges] = useState({}); // Track changes
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch word details on component mount
  useEffect(() => {
    const fetchWord = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/words/${id}`,
          {
            headers: { Authorization: `Bearer ${getAuthToken()}` },
          }
        );

        const wordData = response.data;
        setWord({
          english: wordData.english || '',
          swahili: wordData.swahili || '',
          englishDescription: wordData.english_description || '',
          swahiliDescription: wordData.swahili_description || '',
          video: wordData.video || '',
          image: wordData.image || '',
          images: wordData.images || [],
        });

        if (wordData.image) {
          setImagePreview(`${process.env.REACT_APP_API_MEDIA_URL}/${wordData.image}`);
        }
        if (wordData.video) {
          setVideoPreview(`${process.env.REACT_APP_API_MEDIA_URL}/${wordData.video}`);
        }
        if (wordData.images) {
          setImagePreviews(
            wordData.images.map((img) => `${process.env.REACT_APP_API_MEDIA_URL}/${img}`)
          );
        }
      } catch (error) {
        setError('Failed to fetch word details');
        toast.error('Failed to fetch word details');
      }
    };

    fetchWord();
  }, [id]);

  const handleChange = (field, value) => {
    setWord({ ...word, [field]: value });
    setChanges({ ...changes, [field]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setChanges({ ...changes, image: file });
    }
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
    setChanges({ ...changes, images: files });
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      setVideoPreview(URL.createObjectURL(file));
      setChanges({ ...changes, video: file });
    }
  };

  const getAuthToken = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      return user.token;
    }
    return null;
  };

  const getXSRFToken = () => {
    return Cookies.get('XSRF-TOKEN');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    for (const [key, value] of Object.entries(changes)) {
      if (key === 'images') {
        value.forEach((file, index) => formData.append(`images[${index}]`, file));
      } else {
        formData.append(key, value);
      }
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-word/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getAuthToken()}`,
          'X-XSRF-TOKEN': getXSRFToken(),
        },
      });
      toast.success('Word updated successfully');
      navigate('/manage-words');
    } catch (error) {
      toast.error('Failed to update word');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col w-full h-full max-md:mb-[10px] overflow-y-auto'>
      <div className='flex w-full justify-between'>
        <div className='text-[24px] font-bold mb-6 max-md:mb-1'>Edit Word</div>
        <button onClick={handleSubmit} type="submit" disabled={loading} className='max-md:hidden flex rounded-md text-[14px] h-[44px] w-[100px] bg-blue-500 hover:bg-blue-600 font-bold mb-6 max-md:mb-1 text-white cursor-pointer items-center justify-center'>{loading ? 'Adding...' : 'Submit'}</button>
      </div>
      <form onSubmit={handleSubmit} className='flex flex-col w-full'>
        <div className='text-blue-500 cursor-pointer'>Form</div>
        <div className='flex max-md:flex-col w-full gap-4 md:justify-between'>
          <div className="flex flex-col max-md:w-full w-[50%] md:mb-8">
            <div className="flex flex-col">
              <label className="text-lg font-semibold mb-2" htmlFor="englishWord">English Word</label>
              <input
                type='text'
                value={word.english}
                onChange={(e) => handleChange('english', e.target.value)}
                placeholder='English Word'
                className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-semibold mb-2" htmlFor="englishDescription">English Description</label>
              <textarea
                value={word.englishDescription}
                onChange={(e) => handleChange('englishDescription', e.target.value)}
                placeholder='English Description'
                className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-semibold mb-2" htmlFor="swahiliWord">Swahili Word</label>
              <input
                type='text'
                value={word.swahili}
                onChange={(e) => handleChange('swahili', e.target.value)}
                placeholder='Swahili Word'
                className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-semibold mb-2" htmlFor="swahiliDescription">Swahili Description</label>
              <textarea
                value={word.swahiliDescription}
                onChange={(e) => handleChange('swahiliDescription', e.target.value)}
                placeholder='Swahili Description'
                className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-full bg-zinc-100 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>
          </div>
          {/* Media */}
          <div className="flex flex-col max-md:w-full w-[50%]">
            <div className="flex max-md:flex-col w-full gap-1">
              <div className="flex flex-col md:w-[50%]">
                <label className="text-lg font-semibold mb-2">Image (Single)</label>
                {/* Image preview */}
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt={`${imagePreview && word.english} Image`}
                    className={`${'bg-zinc-200 md:w-full md:h-[180px] md:min-w-[120px] object-cover rounded-md border mr-1'}`}
                  />
                ) : (
                  <div className={`${'flex w-full items-center justify-center bg-zinc-200 md:w-full md:h-[180px] md:min-w-[120px] object-cover rounded-md border mr-1'}`}>
                    No image available
                  </div>
                )}
                <input
                  type='file'
                  onChange={handleImageChange}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-[140px] max-md:w-full bg-zinc-100"
                />
              </div>
              <div className="flex flex-col md:w-[50%]">
                <label className="text-lg font-semibold mb-2">Video</label>
                <div className="flex w- [50%] h-fit max-md:w-full">
                  {/* Video preview */}
                  <video
                    controls
                    src={videoPreview}
                    className="w-[250px] h-[180px] max-md:w-full max-md:h-auto object-cover rounded-lg"
                  />
                </div>
                <input
                  type='file'
                  onChange={handleVideoChange}
                  className="p-3 border border-zinc-100 rounded-lg py-2 px-4 w-[250px] max-md:w-full bg-zinc-100"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-lg font-semibold mb-2">Sketch Images (Multiple)</label>
              <div className='flex gap-4 w-full mt-[10px]'>
                {imagePreviews.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    className='h-[80px] w-[80px] bg-zinc-300 rounded-md object-cover'
                    alt={`preview-${index}`}
                  />
                ))}
              </div>
              <input type='file' multiple onChange={handleImagesChange} />
            </div>
          </div>
        </div>
        <button
          type="submit"
          disabled={loading}
          className='flex object-center md:hidden max-md:w-full rounded-md text-[14px] h-[44px] w-[100px] bg-blue-500 hover:bg-blue-600 font-bold mt-6 text-white cursor-pointer items-center justify-center max-md:mb-[10px]'
        >
          {loading ? 'Updating...' : 'Update Word'}
        </button>
      </form >
    </div >
  );
};

export default EditWord;
