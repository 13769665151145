import React from 'react';
import { Link } from 'react-router-dom';

const LECOManager = () => {
  const documents = [
    { id: 1, title: "Lexical Lists & Dictionaries" },
    { id: 2, title: "Grammars" },
    { id: 3, title: "LOT Project Occasional Papers" },
    { id: 4, title: "Theses & Dissertations" },
    { id: 5, title: "Maps, Facts & Figures" },
    { id: 6, title: "Socio-cultural Issues" },
    { id: 7, title: "Language in Education" },
    { id: 8, title: "Language and Technology" },
    { id: 9, title: "Indigenous Knowledge" },
    { id: 10, title: "Language Description" },
  ];

  const notices = [
    { id: 1, title: "Calendar and Plans" },
    { id: 1, title: "Announcements" },
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full gap-5">
        <div className="font-bold text-[24px] text-blue-500">Documentation</div>
        <div className="grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 gap-2">
          {documents.map((docFile) => (
            <Link to={`/document/${docFile.id}`} key={docFile.id}>
              <div className="cursor-pointer bg-zinc-100 hover:bg-zinc-200 w-fit p-2 rounded-md text-violet-600 hover:text-blue-500">
                {docFile.title}
              </div>
            </Link>
          ))}
        </div>
        <div className="flex flex-col w-full">
          <div className="font-bold text-[24px] text-blue-500">Notices</div>

          <div className="grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 gap-2">
            {notices.map((docFile) => (
              <Link to={`/notices/${docFile.id}`} key={docFile.id}>
                <div className="cursor-pointer bg-zinc-100 hover:bg-zinc-200 w-fit p-2 rounded-md text-violet-600 hover:text-blue-500">
                  {docFile.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="font-bold text-[24px] text-blue-500">Learning and Unlearning</div>
          <div className="grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 gap-2">
            <Link to={`/learning/lecture-notes/1`}>
              <div className="cursor-pointer bg-zinc-100 hover:bg-zinc-200 w-fit p-2 rounded-md text-violet-600 hover:text-blue-500">
                Lecture Notes
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LECOManager;